@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';
@import './_bootswatch_superhero.scss';

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}


//aus Migration


canvas[resize] {
  width: 100%;
  height: 100%;
}


.counter {
    //background-color:#f5f5f5;
    background-color: transparent;
    padding: 20px 0;
    border-radius: 5px;
}

.count-title {
    font-size: 40px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
}

.count-text {
    overflow-wrap: break-word;
    font-size: 13px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
}

.fa-2x {
    margin: 0 auto;
    float: none;
    display: table;
    color: #4ad1e5;
}

/*!
 * Bootstrap Image Checkbox v0.1.0 (https://iqbalfn.github.io/bootstrap-image-checkbox/)
 * Copyright 2019 Iqbal Fauzi
 * Licensed under MIT (https://github.com/iqbalfnn/bootstrap-image-checkbox/blob/master/LICENSE)
 */
.custom-control.image-checkbox {
  position: relative;
  padding-left: 0;
}

.custom-control.image-checkbox .custom-control-input:checked ~ .custom-control-label:after, .custom-control.image-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  opacity: 1;
}

.custom-control.image-checkbox label {
  cursor: pointer;
}

.custom-control.image-checkbox label:before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control.image-checkbox label:after, .custom-control.image-checkbox label:before {
  transition: opacity .3s ease;
  opacity: 0;
  left: .25rem;
}

.custom-control.image-checkbox label:focus, .custom-control.image-checkbox label:hover {
  opacity: .8;
}

.custom-control.image-checkbox label img {
  border-radius: 2.5px;
}

.form-group-image-checkbox.is-invalid label {
  color: #dc3545;
}

.form-group-image-checkbox.is-invalid .invalid-feedback {
  display: block;
}
/*# sourceMappingURL=bootstrap-image-checkbox.css.map */



.centered-over {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  left: 40%;
  text-align: center;
}



.green_text {
  color: #00aa44;
}


.img-hitlist {
  min-width: 100px;
  width:100%;
}
figure {
    display: inline-block;
    /* border: 1px dotted gray;
    margin: 20px; adjust as needed */
}
figure img {
    vertical-align: top;
}
figure figcaption {
    /* border: 1px dotted blue; */
  vertical-align: top;
    text-align: center;
}
#word_cloud span {cursor:pointer; }

#box {
    pointer-events: none;
    position: absolute;
    box-shadow: 0 0 200px 200px rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    cursor: pointer;
  }

#searchcloud-container {
    overflow-x: auto;
    overflow-y: visible;
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
  }

.wall,
.brick { width: 20%; }
